import Nav from './components/NavBar';
import HeaderPage from './components/Header/HeaderPage';
import About from './components/AboutMe';
import Contact from './components/Contact';
import Projects from './components/Projects';
import './App.css';


function App() {
  return (
    <div>

      <div id="" className="bg-neutral-500">
        <Nav/>
        <HeaderPage/>
      </div>

      <div id="about" className="bg-neutral-300 ">
        <About/>
      </div>

      <div id="proj" className="bg-neutral-100">
        <Projects/>
      </div>

      <div id="con" className="bg-neutral-700">
        <Contact/>
      </div>

    </div>
  );
}

export default App;
