import "./PaddingXAxis.css";
import "./Transform.css";

import portrait from "./images/portrait.jpg";
import Resume from "./resume/Javier_Resume.pdf";
import Aos from "aos";
import React from "react"
import "aos/dist/aos.css";
import { useEffect } from "react";

function AboutMe() {

    useEffect(() => {
        Aos.init({duration: 200});
    }, []);

    return(
        <div id="" className="PaddingX ">
            
            <div className=" lg:flex-row flex flex-col-reverse lg:py-52 py-10 ">

                <ul data-aos="fade-down-right" className="lg:w-3/4 pr-16 aos-fix">
                    <li><h1  className="pt-18 text-3xl">Hi I'm Javier Chung.</h1></li>
                    <li><p className="mt-16  indent-10 lg:text-lg text-sm">I am an aspiring undergraduate third year software engineering student currently studying at Ontario Tech University.
                     I would describe myself as an ambitious student, open to learning new skills and ready to take on any challenge. 
                        As an energetic person, I strive to prove it through the results of my work. </p></li>

                    <li><p className="mt-10 indent-10 lg:text-lg text-sm">Currently I seek to apply my software and web developer skills and gain valuable experience in a year-long internship this summer of
                    2023. I am also working on a calender schedule program built with java.</p></li>
                    <li><a href={Resume}><p className=" mt-10 text-xl bg-neutral-800 rounded-lg text-white w-fit px-1.5">RESUME</p></a></li>

                </ul>

                <ul data-aos="fade-up-left" className="py-10 aos-fix">
                    <li><img className="rounded-full" src={portrait} alt="Portrait circle" width={300} height={300}/></li>
                </ul>

            </div>

        </div>
    );
}

export default AboutMe;

