import Resume from "./resume/Javier_Resume.pdf"
import "./PaddingXAxis.css";
import {FaBars} from 'react-icons/fa';
import React, {useState} from 'react';

function Navbar() {

    //change color of nav when page is offset
    const [navColor, setNavColor] = useState(false);
    const changeNav = () => {
        if (window.scrollY >= 1){
            setNavColor(true);
        }else{
            setNavColor(false);
        }
    }

    window.addEventListener('scroll', changeNav)

    //shrinking nav
    const [shrinkNav, setShrinkNav ] = useState(false)

    return (
        <div className="">

            <ul className="">
                <div id=""  className={navColor? "bg-neutral-700 text-white PaddingX fixed justify-between w-full inline-flex  duration-700" : " PaddingX fixed justify-between w-full inline-flex duration-700"}>
                    <div>
                        <li className="hover:text-gray-400 font-extrabold"><a href="app.js#home">J . C</a></li>
                    </div>



                    <div className={(shrinkNav ? "left-0 PaddingX" : "-left-full") + " md:static fixed bottom-0 top-9 md:flex md:space-x-5 items-center md:bg-transparent bg-opacity-80 bg-neutral-800 md:w-auto w-full md:space-y-0 space-y-5 "}>
                        <li className="hover:text-gray-400"><a href="App.js#home" >Home</a></li>
                        <li className="hover:text-gray-400"><a href={Resume} >Resume</a></li>
                        <li className="hover:text-gray-400"><a href="App.js#about" >About</a></li>
                        <li className="hover:text-gray-400"><a href="App.js#proj" >Projects</a></li>
                        <li className="hover:text-gray-400"><a href="App.js#con" >Contact</a></li>
                    </div>

                    <div className="md:block md:absolute md:top-0 md:right-0 md:translate-x-10 md:text-md md:cursor-pointer">
                        <FaBars onClick={() => setShrinkNav(!shrinkNav)} className=" cursor-pointer text-xl pt-1"></FaBars>
                    </div>

                </div>
            </ul>

        </div>
    );

    
}

export default Navbar;