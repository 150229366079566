import "./PaddingXAxis.css";
import {AiFillPhone} from 'react-icons/ai';
import {AiFillMail} from 'react-icons/ai';
import {AiFillLinkedin} from 'react-icons/ai';
import {AiFillGithub} from 'react-icons/ai';
import React, {useState} from "react";

function Contact() {

    const [popUp, updatePopUp] = useState(false);

    const usePopUp = () => {
        updatePopUp(!popUp);
    }

    const [mail, updateMailpop] = useState(false);

    const useMail = () => {
        updateMailpop(!mail);
        console.log(mail);
    }

    return(
        <div className="PaddingX text-white ">

            <div className="flex space-x-5 justify-center py-5">
                <button onClick={useMail}><AiFillMail  size={50}></AiFillMail></button>
                <button onClick={usePopUp}><AiFillPhone size={50}></AiFillPhone></button>

                <div onClick={usePopUp} className={popUp ? "fixed top-0 -left-7 right-full bottom-0 w-screen h-screen bg-neutral-400/75": "fixed top-full -left-7 right-0 bottom-0 w-screen h-screen bg-neutral-400/75"}>
                    <h className="absolute top-1/3 left-1/2 -translate-x-1/3 text-black bg-white p-24">+1-(581)-308-5977</h>
                </div>

                <div onClick={useMail} className={mail ? "fixed top-0 -left-7 right-full bottom-0 w-screen h-screen bg-neutral-400/75": "fixed top-full -left-7 right-0 bottom-0 w-screen h-screen bg-neutral-400/75"}>
                    <div className="absolute top-1/3 left-1/2 -translate-x-1/3 text-black bg-white p-24">
                        <ul className=" space-y-10 ">
                            <li className="hover:text-neutral-400"><a href="mailto: javier23chung@gmail.com">javier23chung@gmail.com</a></li>
                            <li className="hover:text-neutral-400"><a href="mailto: javier.chung@ontariotechu.net">javier.chung@ontariotechu.net</a></li>
                        </ul>
                    </div>
                </div>

                <a href="https://github.com/Javiebear"><AiFillGithub size={50}></AiFillGithub></a>
                <a href="https://www.linkedin.com/in/javier-chung-a91415245/"><AiFillLinkedin size={50}></AiFillLinkedin></a>
            </div>

            <div className=" pb-5">
                <div><p className=" text-sm text-center">Built With Reactjs and Tailwindcss</p></div>
            </div>


        </div>
    );
}

export default Contact;