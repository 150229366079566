import "./HeaderPage.css"
import {AiFillLinkedin} from 'react-icons/ai';
import {AiFillGithub} from 'react-icons/ai';
import React from "react"
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



function HeaderPage() {

    useEffect(() => {
        Aos.init({duration: 700});
    }, []);

    return(
        <div id="" className="PaddingX ">

            <div data-aos="fade-down" id="bottomGray" className="text-left lg:flex lg:flex-row flex justify-end aos-fix">

                <ul id="headerList" className="lg:pt-5 text-white">
                    <li><h2 id="nameMargin" className="text-5xl">I am Javier Chung</h2></li>
                    <li><h3 id="feildMargin" className=" text-1xl">Software Engineering Student</h3></li>
                    <li><h3 class="smallTextMargin" className=" text-1xl">Ontario Tech University</h3></li>

                    <ul class="smallTextMargin flex space-x-3">
                        <li><a href="https://github.com/Javiebear"><AiFillGithub></AiFillGithub></a></li>
                        <li><a href="https://www.linkedin.com/in/javier-chung-a91415245/"><AiFillLinkedin></AiFillLinkedin></a></li>

                    </ul>

                </ul>
                
            </div>

        </div>
    );
}

export default HeaderPage;