
import "./PaddingXAxis.css";
import Hang from "./images/Hang.PNG";
import ArtGal from "./images/Art.PNG";
import React from "react"
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "./Transform.css";

function Projects() {

    useEffect(() => {
        Aos.init({duration: 300});
    }, []);

    return (
        <div id="" className="PaddingX ">
            <div className="pb-24 pt-10">

                <div data-aos="zoom-in-down" className="aos-fix"><h1 className="text-center text-5xl">Projects</h1></div>


                <div data-aos="zoom-in-up" className="aos-fix">
                    <div className="mt-24 ">
                        <div className=""><h3 className="text-3xl">Hangman Game</h3></div>
                    </div>

                    <div className="lg:flex-row flex flex-col lg:space-x-56 lg:text-base text-sm">
                        <div className="mt-5"><a href="https://github.com/Javiebear/Hangle"><img className="max-w-full" src={Hang} alt="" width="1000" height=""/></a></div>
                        <h1 className="lg:pt-4 indent-10"> A mix of wordle and hangman, this game was written using reactJS. Features include a set amount of lives, an incorrect letter bank and an area for users to guess their letter.
                         To play this game, the user must guess the five letter word before they run out of lives.</h1>
                    </div>

                </div>

                <div data-aos="zoom-in-up" className="aos-fix">
                    <div className="mt-24">
                        <div><h3 className="text-3xl">Art Gallery Website</h3><p className="text-xl"></p></div>
                    </div>
                    
                    <div className="lg:flex-row flex flex-col lg:space-x-56 lg:text-base text-sm">
                        <div className="mt-5"><a href="https://github.com/Javiebear/Art_Gallery"><img className="max-w-full" src={ArtGal} alt="" width="1500" height=""/></a></div>
                        <h1 className="lg:pt-4 indent-10"> The Frigus Art Gallery provides a system where users are able to view artworks sorted into thier according genre. The web application was built using php, mysql and css.
                        Features of this website iclude an area to view art works and a page where users can book a day to visit. Using a database the users information is stored and can only be seen though the admin login on a seperate page.</h1>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Projects;

